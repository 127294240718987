import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Asset } from './asset.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RestApiService {

  apiUrl = 'http://localhost:62677/api';

  constructor(private http: HttpClient) { }

  getAsset(): Observable<Asset>{
    return this.http.get<Asset>(this.apiUrl + '/Asset?type=json');
  }

  getAssetById(id): Observable<Asset> {
    return this.http.get<Asset>(this.apiUrl + '/Asset/' + id + '?type=json');
}
}