import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AssetDetailComponent } from './asset-detail/asset-detail.component';
import { AssetListComponent } from './asset-list/asset-list.component';

const routes: Routes = [
  {path: '', pathMatch: 'full', redirectTo: 'assetlist' },
  {path: 'assetdetail/:id', component: AssetDetailComponent },
  {path: 'assetlist', component: AssetListComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
