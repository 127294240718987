import { Component, OnInit } from '@angular/core';
import { RestApiService } from '../shared/rest-api.service';

@Component({
  selector: 'app-asset-list',
  templateUrl: './asset-list.component.html',
  styleUrls: ['./asset-list.component.css']
})
export class AssetListComponent implements OnInit {

  Asset: any = [];

  constructor(public restApi: RestApiService) { }

  ngOnInit() {
    this.loadAsset();
  }

  loadAsset() {
    return this.restApi.getAsset().subscribe( data => this.Asset = data);
  }

}
